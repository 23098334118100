import React, { useState, useMemo } from 'react'
import { memo } from 'react'
import { Calendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { parseDateString } from '@dy/commons/utils'
import styled from 'styled-components'
import { mq, vw } from '@dy/commons/styles'
import { Figure } from '@dy/commons/components'

interface ICalendar {
  deliveryDateOptions: string[],
  onSelectedDay: (date: Date) => void,
  defaultDeliveryDate: string
}

function getMadridDate(dateString?: Date | string): Date {
  const timeZone = 'Europe/Madrid'

  let date: Date

  if (dateString) {
    // Convert the input date string to a valid Date object
    date = new Date(dateString)
    if (isNaN(date.getTime())) {
      console.warn(`🗓️ Invalid date string: ${dateString}. Returning current Madrid date.`)
      date = new Date()
    }
  } else {
    date = new Date() // Default to now
  }

  // Convert the date to Madrid timezone
  const madridTimeString = new Intl.DateTimeFormat('en-US', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23', // 24-hour format
  }).format(date)

  // Extract the Madrid date components from the formatted string
  const [month, day, year, hour, minute, second] = madridTimeString.match(/\d+/g)!.map(Number)

  // Create a new Date object in Madrid timezone
  return new Date(year, month - 1, day, hour, minute, second)
}

function getFurthestDate(dates: string[]): string | null {
  if (!dates || dates.length === 0) {
    console.warn('🗓️ Furthest date -> No valid dates provided.')
    return null
  }

  return dates.reduce((furthest, current) =>
    new Date(current) > new Date(furthest) ? current : furthest
  )
}

const getReadableDates = (dates) => dates.map((item) => parseDateString({dateString: item}))

const backIconLight = {
  type: 'svg' as const,
  src: '/images/svg/chevron--left.svg',
  alt: 'Go back icon',
  size: { width: 12, height: 12}
}

const reactIconCalendarComponent = (next) => {
  return(
    <Icon className={next? 'rigth' : ''}>
      <Figure media={backIconLight} fill={false} />
    </Icon>
  )
}

export const DeliveryCalendar = memo<ICalendar>(({ deliveryDateOptions, onSelectedDay, defaultDeliveryDate }) => {
  // console.log('calendar deliveryDateOptions', deliveryDateOptions)
  const [date, setDate] = useState(getMadridDate(defaultDeliveryDate ?? null))
  const transFormingDates = useMemo(() =>  deliveryDateOptions && getReadableDates(deliveryDateOptions), [deliveryDateOptions])
  const furthestDate = useMemo(() => getMadridDate(getFurthestDate(deliveryDateOptions)), [deliveryDateOptions])
  // console.log('======================')
  // useEffect(() => {
  //   console.log('🟡 date', date)
  // }, [date])

  // useEffect(() => {
  //   console.log('calendarDates', transFormingDates)
  // }, [transFormingDates])

  // useEffect(() => {
  //   console.log('furthestDate', furthestDate)
  // }, [furthestDate])
  return (
    <Div>
      <Calendar onChange={setDate} value={date} formatMonthYear={(_locale, date) => date.toLocaleString('es-ES', { month: 'long', year: 'numeric'})}
        selectRange={false} onClickDay={onSelectedDay} locale={'es-ES'} defaultView={'month'} minDate={getMadridDate(deliveryDateOptions?.[0])} maxDate={furthestDate} nextLabel={reactIconCalendarComponent(true)} prevLabel={reactIconCalendarComponent(false)}
        tileClassName={({ date }) => {
          if(transFormingDates && transFormingDates.includes(parseDateString({ dateString: date })) ){
            return 'highlight'
          }
        }}
      />
    </Div>
  )
})

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.rigth {
    transform: rotate(180deg);
  }
  figure {
    width: 20px;
  }
`
const Div = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: ${vw(20, 'mobile')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${vw(10, 'mobile')};
  min-height: 350px;

  ${mq.greaterThan('nexus7')} {
    border-radius: ${vw(20, 'nexus7')};
    margin-bottom: ${vw(10, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    border-radius: ${vw(20, 'desktop')};
    height: 100%;
    margin-bottom: ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    border-radius: 20px;
    margin-bottom: 30px;
  }

  .react-calendar {
    background: #fff;
    border: unset;
    color : ${({ theme }) => theme.colors.darkred} !important;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    font-size: 10px !important;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: -.33px;
    line-height: 13px !important;
    max-width: 95%;
    min-height: 270px;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      font-size: 12px !important;
      line-height: 15px !important;
      width: ${vw(350, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      width: 350px;
    }
  }

  .react-calendar__navigation__label,
  .react-calendar__tile {
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -.33px;

    ${mq.greaterThan('tablet')} {
      font-size: 11px !important;
    }

    ${mq.greaterThan('desktop')} {
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }

  .react-calendar__month-view__weekdays {
    font-size: 10px !important;
    font-weight: 300;
    letter-spacing: -.33px;

    ${mq.greaterThan('tablet')} {
      font-size: 8px !important;
    }

    ${mq.greaterThan('desktop')} {
      font-size: 10px !important;
    }
  }
  .react-calendar__tile {
    flex: 0 0 13.9% !important;
  }


  .react-calendar__navigation__label__labelText,
  .react-calendar__tile:not(.highlight, .default_selected),
  .react-calendar__month-view__days__day--weekend {
    color : ${({ theme }) => theme.colors.darkred} !important;
  }

  .react-calendar__navigation__label {
    cursor: none;
    pointer-events: none;
  }
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    background: transparent;
    border: unset;
    cursor: pointer;

    &:hover,
    &:enabled,
    &:disabled {
      background-color: white !important;
    }
  }

  abbr[title]{
    border-bottom-color: inherit !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    cursor: auto;
  }

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display:none;
  }

  .default_selected {
    background: ${({ theme }) => theme.colors.creme} !important;
  }

  .highlight, .default_selected {
    background: ${({ theme }) => theme.colors.white};
    border: .5px solid ${({ theme }) => theme.colors.red} !important;
    color: ${({ theme }) => theme.colors.darkred} !important;
    cursor: pointer;
    padding: 1px;
    pointer-events: auto;
    user-select: auto;

    &:hover {
      background: ${({ theme }) => theme.colors.creme};
      color: ${({ theme }) => theme.colors.red} !important;
    }
  }


  .react-calendar__tile--now {
    background: #dedede !important;
    &:hover,
    &:enabled {
      background: #dedede !important
    }
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 0.75em 0.5em;
    text-align: center;
}
  .react-calendar__month-view__days {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 4px !important;
  }

  .react-calendar__tile:not(.highlight, .default_selected ) {
    background: #dedede !important;
    border: 1px solid white !important;
    opacity: .5;
    pointer-events: none;
    user-select: none;
    width: 10px !important;

    &:hover,
    &:enabled {
      background: #dedede !important;
    }
  }

  .react-calendar__tile--active {
    &:enabled,
    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }) => theme.colors.creme} !important;
    }
  }
`
