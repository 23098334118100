import { type FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import { Button } from '@dy/commons/components'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP12_1, getP15_1B, getP12_2, hex2Rgba, getP15, getP10_1 } from '@dy/commons/styles'
import { useTranslator, useCustomer } from '@/hooks'
import { currencyFormatter, cookie } from '@dy/commons/utils'
import { useCart, useModal, useToast } from '@dy/commons/hooks'
import { CardListItem } from '../account/sharedAccountStyles'
import { listVariants } from '../cart'
import type { TypeTranslationsJsonKeys } from '@/context'
import { getDynamicText, getDayOfWeekName, getDateFormatted } from '@dy/commons/utils'
import { TypeCMSCart, TypeCartLine } from '@dy/commons/schema'
import { useMutation } from '@apollo/client'
import { MUTATION_CART_DELIVERY_DATE, MUTATION_CART_SYNC } from 'api/graphql'
import { DeliveryCalendar } from './DeliveryCalendar'
import { MODALS } from '@dy/commons/context'
interface Props {
  data: TypeCMSCart
}

const getMoneySaved = items => {
  let savedAmount = 0
  items.map(item => { savedAmount = (item.originalPrice - item.listPrice) * item.quantity })
  return savedAmount
}

function getCartLineWithLatestDate(cartLines:TypeCartLine[]):TypeCartLine  {
  if (cartLines.length === 0) return null

  let cartLineWithLatestDate = cartLines[0]

  // Initialize the latestDate with the first object's deliveryDate
  let latestDate = new Date(cartLines[0].variant.deliveryDate)

  // Iterate over the array of objects to find the latest date
  for (let i = 1; i < cartLines.length; i++) {
    const deliveryDate = new Date(cartLines[i].variant.deliveryDate)
    if (deliveryDate > latestDate) {
      cartLineWithLatestDate = cartLines[i]
      latestDate = deliveryDate
    }
  }

  return cartLineWithLatestDate
}

export const CartSummary:FC<Props> = memo(({ data }) => {
  const { locale } = useRouter()
  const { t } = useTranslator()
  const { setLoaderActive, customer } = useCustomer()
  const { updatePayload } = useModal(MODALS.STOCK_CART)
  const { subtotalExTax, cartLines, multipleDeliveryDates, deliveryDate, deliveryDateOptions, totalExTax, bulkUpdateCart, isFrozenCart } = useCart()
  const { agency, agencyText, minimumPurchase, minimumPurchaseFrozen } = data.deliverySchedules[0] || {}
  const { cartLines:cartLinesFromQuery, promotions } = data
  const orderedCartLines = getCartLineWithLatestDate(cartLinesFromQuery)
  const [isDisabled, setIsDisabled] = useState(true)
  const promotionType = useMemo(() => promotions[0]?.rules[0]?.ruleable.discount_by, [promotions])
  const promotionAmount = useMemo(() => promotions[0]?.rules[0]?.ruleable.value, [promotions])
  const promotionSaved = useMemo(() => (subtotalExTax *  Number(promotionAmount)/100).toFixed(2) , [promotionAmount, subtotalExTax])
  const blockedMinimumPurchase = useMemo(() => (isFrozenCart && subtotalExTax < minimumPurchaseFrozen) || (!isFrozenCart && subtotalExTax < minimumPurchase), [isFrozenCart, minimumPurchaseFrozen, minimumPurchase, subtotalExTax])
  const numberMinimPurchase = useMemo(() => isFrozenCart ? minimumPurchaseFrozen : minimumPurchase, [isFrozenCart, minimumPurchaseFrozen, minimumPurchase])
  const [dateSelectedByUser, setDateSelectedByUser] = useState<string>(null)
  const { addToast } = useToast()
  // Money saved in this purchase
  const moneySaved = useMemo(() => getMoneySaved(cartLines), [cartLines])

  const [cartSync, { error:cartSyncApolloError }] = useMutation(MUTATION_CART_SYNC)
  const [cartDeliveryDate, { error:apolloError }] = useMutation(MUTATION_CART_DELIVERY_DATE)

  useEffect(() => {
    console.log('🟢 deliveryDateOptions', deliveryDateOptions)
    // 2025-04-04T00:00:00+02:00
    // 2025-02-20T00:00:00.000Z
  }, [deliveryDateOptions])

  const onClickCheckout = async () => {
    setLoaderActive(true)
    setIsDisabled(true)

    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    // Update CMS
    const mutationData = {
      context: {
        isPrivatePath: true,
        DYCustomerToken
      }
    }


    try {
      const resCartSync = await cartSync(mutationData)
      const cartId:string = resCartSync?.data?.cartSync?.cart?.bigcommerceId
      const notifications = resCartSync?.data?.cartSync?.cart?.notifications
      if(Array.isArray(notifications) && notifications.length > 0) {
        setLoaderActive(false)
        const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions,  multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = resCartSync.data?.cartSync?.cart
        bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
        updatePayload({ payload: notifications, open: true })
        return
      }

      if(!cartId){
        setLoaderActive(false)
        addToast({
          message: t('cart.cart_date_error') as string,
          type: 'error'
        })
        return
      }
      console.log('🛒', cartId)
      console.log('🛒 resCartSync', resCartSync)
      const cartUrl = `/cart.php${resCartSync?.data?.cartSync?.cartUrl?.split('/cart.php')?.[1]}`
      // console.log('cartUrl', cartUrl)

      // console.log('customer', customer)
      // OPTION 1
      const resBC = await fetch('/api/bigcommerce-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customer?.bigcommerceId,
          redirectTo: cartUrl,
        }),
      })

      const resBCdata = await resBC.json()
      // console.log('🟣resBCdata.url', resBCdata.url)
      if (typeof resBCdata.url === 'string') {
        window.location.href = resBCdata.url
      } else {
        setLoaderActive(false)
      }
      // END OPTION 1


      // OPTION 2
      // const res = await fetch('/api/checkout-url', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ cartId }),
      // })
      // const data = await res.json()
      // if (typeof cartId === 'string') {
      //   window.location.href = data.url
      // } else {
      //   setLoaderActive(false)
      // }
      // END OPTION 2
    } catch (error) {
      setLoaderActive(false)
      console.log('Error trying to sync cart', error)
    }
  }

  useEffect(() => {
    if(blockedMinimumPurchase) setIsDisabled(true)
    else if (cartLines?.length > 0) setIsDisabled(false)

  }, [blockedMinimumPurchase, cartLines])

  useEffect(() => {
    if(deliveryDate) {
      const parsedDate = new Date(deliveryDate).toDateString()
      setDateSelectedByUser(parsedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!apolloError) return
    setLoaderActive(false)
    addToast({
      message: t('cart.cart_date_error') as string,
      type: 'error'
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloError])

  useEffect(() => {
    if(!cartSyncApolloError) return
    console.log('🟡 cartSyncApolloError', cartSyncApolloError)
    setLoaderActive(false)
    addToast({
      message: t('cart.cart_error') as string,
      type: 'error'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartSyncApolloError])

  const onSelectedDay = useCallback(async (date) => {
    const parsedDate = new Date(date).toDateString()

    setDateSelectedByUser(parsedDate)
    setLoaderActive(true)

    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    console.log('RAW date', date)
    console.log('parsedDate', parsedDate)
    // Update CMS
    const mutationData = {
      variables: {
        deliveryDate: parsedDate
      },
      context: {
        isPrivatePath: true,
        DYCustomerToken
      }
    }

    try {
      const resCartDeliveryDate = await cartDeliveryDate(mutationData)
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions,  multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = resCartDeliveryDate.data?.cartDeliveryDate.cart
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)

      setLoaderActive(false)
      if(!cartId) {
        console.log('🟠 resCartDeliveryDate returned cart data without bigcommerceId')
        return
      }
      // console.log('🟠 data.url', data.url)


    } catch (error) {
      setLoaderActive(false)
      console.log('Error trying to sync cart', error)
    }

  }, [bulkUpdateCart, cartDeliveryDate, setLoaderActive])

  return (
    <AnimatePresence>
      <CartWrapper>
        <CardListItem className='small'>
          <div className='order_title'>
            <h1>{t('account.order.order_details.payment_summary')} ({cartLines.length})</h1>
          </div>
          <table>
            <tbody>
              <tr>
                <td>{t('account.order.order_details.subtotal_products')}</td>
                <td>{typeof subtotalExTax === 'number' && currencyFormatter({ price: subtotalExTax, localeRegion: locale, round: false, trailingZeros: true })}<span> +{t('account.order.taxes')}</span></td>
              </tr>
              {/* {coupon &&
                <tr>
                  <td>{t('account.order.order_details.voucher')} </td>
                  <td>{coupon}</td>
                </tr>
              } */}
              {promotions.length > 0 &&
                <tr>
                  <td>{t('account.order.order_details.discount')}</td>
                  <td>{promotionType === 'percentage' ? `${Number(promotionAmount)}%` : `${Number(promotionAmount)}€`} {promotionType === 'percentage' && ` (-${Number(promotionSaved)} €)`}</td>
                </tr>
              }
              <tr>
                <td>{t('account.order.order_details.shipment')}</td>
                <td>{t('commons.cart.free')}</td>
              </tr>
              <tr>
                <td>{t('account.order.order_details.total_products')}</td>
                <td>{typeof totalExTax === 'number' && currencyFormatter({ price: totalExTax, localeRegion: locale, round: false, trailingZeros: true })}<span> +{t('account.order.taxes')}</span></td>
              </tr>
            </tbody>
          </table>
          {/* <div className='summary-total'>
            <span>{t('account.order.order_details.total_inc_tax')}</span>
            <span>{typeof totalExTax === 'number' && currencyFormatter({ price: totalExTax, localeRegion: locale, round: false, trailingZeros: true })}</span>
          </div> */}
          {deliveryDate && !agency && Array.isArray(cartLines) && cartLines.length > 0 &&
            <div className='delivery-date'>
              <SpanDeliveryDate>
                {getDynamicText(t('cart.delivery_date' as TypeTranslationsJsonKeys) as string, {
                  deliveryDate: getDateFormatted(locale, dateSelectedByUser ?? deliveryDate)
                })}
                {!multipleDeliveryDates ?
                  <SpanDeliveryDate className='opacity'>{getDynamicText(t(`cart.delivery_date_${cartLines[0]?.variant.deliveryType}` as TypeTranslationsJsonKeys) as string, {
                    deliveryDate: getDateFormatted(locale, dateSelectedByUser ?? deliveryDate),
                    onDemandDay: getDayOfWeekName(locale, cartLines[0]?.variant.onDemandDay),
                    deliveryHour: cartLines[0]?.variant.deliveryType === 'on_demand' ? '14h' : '21h'
                  })}</SpanDeliveryDate>
                  :
                  <SpanDeliveryDate className='opacity'>{getDynamicText(t(`cart.delivery_date_${orderedCartLines.variant.deliveryType}` as TypeTranslationsJsonKeys) as string, {
                    deliveryDate: getDateFormatted(locale, dateSelectedByUser ?? deliveryDate),
                    onDemandDay: getDayOfWeekName(locale, orderedCartLines.variant.onDemandDay),
                    deliveryHour: orderedCartLines.variant.deliveryType === 'on_demand' ? '14h' : '21h'
                  })}</SpanDeliveryDate>
                }
              </SpanDeliveryDate>
              {/* {multipleDeliveryDates && <span className='opacity'> {t('account.order.several_dates')}</span>} */}
            </div>
          }
          {agency && Array.isArray(cartLines) && cartLines.length > 0 &&
            <div className='delivery-date'>
              <SpanDeliveryDate>
                {agencyText}
              </SpanDeliveryDate>
            </div>
          }
        </CardListItem>
        {moneySaved > 0 &&
          <CartTooltip variants={listVariants} $moneySaved={moneySaved} initial='inactive' animate={'enter'} exit={'exit'}>
            <p>{t('cart.order_saved')} {currencyFormatter({price: moneySaved, localeRegion:locale})}</p>
          </CartTooltip>
        }
        {!agency && <DeliveryCalendar deliveryDateOptions={deliveryDateOptions} onSelectedDay={onSelectedDay} defaultDeliveryDate={deliveryDate}/>}
        <Button disabled={isDisabled} onClick={onClickCheckout} bgType='red'>{t('commons.actions.next')}</Button>
        {blockedMinimumPurchase &&
          <CartTooltip variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'}>
            <p>{t('cart.minimum_order')} {currencyFormatter({price: numberMinimPurchase, localeRegion:locale})}</p>
          </CartTooltip>
        }
      </CartWrapper>
    </AnimatePresence>
  )
})

const CartTooltip = styled(motion.div)<any>`
  background-color: ${({ theme, $moneySaved }) => $moneySaved ? theme.colors.creme : theme.colors.red };
  width:  ${({ $moneySaved }) => $moneySaved ? '100%' : '50%'};
  height: 100%;
  padding: ${vw(21, 'mobile')} ${vw(10, 'mobile')};
  position: relative;
  left: 0;
  top: 0;
  margin: ${vw(15, 'mobile')} auto;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(21, 'nexus7')} ${vw(10, 'nexus7')};
    margin: ${vw(15, 'nexus7')} auto;
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(21, 'desktop')} ${vw(10, 'desktop')};
    height: 100%;
    margin: ${vw(15, 'desktop')} auto;
  }

  ${mq.greaterThan('desktop')} {
    margin: 15px auto;
    padding: 21px 10px;
  }

   &:after {
    content: "";
    border: ${vw(10, 'mobile')} solid transparent;
    border-bottom-color: ${({ theme, $moneySaved }) => $moneySaved ? theme.colors.creme : theme.colors.red};
    display: block;
    left: 46%;
    position: absolute;
    top: ${vw(-18, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      top: ${vw(-18, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border: ${vw(10, 'desktop')} solid transparent;
      border-bottom-color: ${({ theme, $moneySaved }) => $moneySaved ? theme.colors.creme : theme.colors.red};
      left: 45%;
      top: ${vw(-18, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border: 10px solid transparent;
      border-bottom-color: ${({ theme, $moneySaved }) => $moneySaved ? theme.colors.creme : theme.colors.red};
      top: -18px;
    }
  }

  p {
    ${getP12_1()}
    background-color: none;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }
`

const CartWrapper = styled.section<any>`
  grid-column: 1 / span 6;
  height: fit-content;
  margin: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')} {
    grid-column: 9 / 12;
    margin: 0px;
  }

  .small {
    h1, span, td {
      color: ${({ theme }) => theme.colors.red};
    }
    td {
      >span {
        opacity: .5;
      }
    }
    span, td {
      ${getP15_1B()}
    }

    table {
      width:100%;
      padding: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 12px 20px 15px;
      }

      tr {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: ${vw(5, 'mobile')} 0;

        ${mq.greaterThan('nexus7')} {
          padding: ${vw(5, 'nexus7')} 0;
        }

        ${mq.greaterThan('tablet')} {
          padding: ${vw(10, 'desktop')} 0;
        }

        ${mq.greaterThan('desktop')} {
          padding: 10px 0;
        }
      }
    }

    .delivery-date,
    .summary-total {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
      padding: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(30, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 12px 20px 15px;
      }
    }

    .summary-total, .opacity {
      opacity: .4;
    }

    .opacity {
      ${getP10_1()}
      color: ${({ theme }) => theme.colors.text};
      margin-top: ${vw(10, 'mobile')};
      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(10, 'nexus7')};
      }
      ${mq.greaterThan('tablet')} {
        margin-top: ${vw(10, 'desktop')};
      }
      ${mq.greaterThan('desktop')} {
        margin-top: 10px;
      }
    }
    .delivery-date {
      ${getP12_2()}
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;

      > span {
        color: ${({ theme }) => theme.colors.darkred};
      }
    }
  }

  button {
    width: 100%;

    &[disabled],
    &.block {
      pointer-events: none;
      user-select: none;
      opacity: .5;
    }
  }
`


const SpanDeliveryDate = styled.span`
  ${getP15()}
  display: block;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  /* width: 100%; */
`
