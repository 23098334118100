import { type FC, memo, createContext, useEffect, useMemo, useState, useCallback, useContext, useRef } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, deleteVariants, deleteButtonVariants, figureVariants, defaultVariants, ButtonWrapper, Counter, Units, counterVariants } from '@dy/commons/components'
import { useDeviceType, useModal, useCart, useToast } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import type { TypeTranslationsJsonKeys } from '@/context'
import { CUSTOMER_ADD_TO_CART, CUSTOMER_UPDATE_CART_ITEM, CUSTOMER_DELETE_FROM_CART, GET_CART } from '@/api'
import { cookie, CART_LINE_TYPE, getPathnameType, hasPrivateStore, getDynamicText, pushAnalyticsEvent } from '@dy/commons/utils'
import type { TypeCartLine } from '@dy/commons/schema'
import { useTranslator, useActiveVariant, useCustomer } from '@/hooks'
// import type { TypeCart, TypeCartLine } from '@dy/commons/schema'

//trigger deploy
type NewCartItemsContextType = {
  cartItems: any[],
  setCartItems:Dispatch<SetStateAction<any[]>>
}

type TypeButtonDelete = {
  unitCount: number
  backToSingleUnit: boolean
  minSaleUnits: number
  readonly isPDP: boolean
  onClick: (increase) => void
  onClickBin: () => void
  frozen?: boolean,
  isBox?: boolean,
  cartLinesQuantity?: number,
  qtyPerBox?: number
}

export const NewCartItemsContext = createContext<NewCartItemsContextType | null>(null)

const getBoxCount = ({ isBox, unitCount, qtyPerBox }) => {
  return isBox && typeof qtyPerBox === 'number' ? Math.floor(unitCount / qtyPerBox) : unitCount
}

const ButtonDelete = memo(({ unitCount, minSaleUnits, isPDP, onClick, onClickBin, frozen = false, isBox, cartLinesQuantity, qtyPerBox }:TypeButtonDelete) => {
  const { isMobile, size } = useDeviceType()
  const animate = useMemo(() => isPDP ? 'expandedPDP' : (size.width >= 603 && size.width < 768) ? 'expandedNexus' : isMobile ? 'expandedMobile' : (size.width >= 1650) ? 'expandedWide':'expandedDesktop', [isMobile, isPDP, size.width])
  const boxCount = useMemo(() => getBoxCount({ isBox, unitCount, qtyPerBox }), [unitCount, qtyPerBox, isBox])
  const initialAndExit = useMemo(() => isPDP ? 'collapsedPDP' : 'collapsed', [isPDP])
  const showBin = useMemo(() => (isBox ? boxCount : unitCount) === (isBox ? 1 : minSaleUnits), [isBox, boxCount, unitCount, minSaleUnits])

  return (
    <motion.div className='btn-bin' initial={initialAndExit} variants={deleteVariants} exit={initialAndExit} animate={animate}>
      <Button circle={true} noHoverEffect={true} bgType={frozen ? 'frozen' : 'red'} onClick={() => { isBox ? (cartLinesQuantity === qtyPerBox) ? onClickBin() : onClick(false) : unitCount === minSaleUnits ? onClickBin() : onClick(false) }} name='Remove unit' initial='collapsed' variants={deleteButtonVariants} animate='expanded' exit='collapsed'>
        <motion.figure key={`one-${unitCount === minSaleUnits}`} initial={'binCollapsed'} variants={figureVariants} animate={showBin ? 'fadeIn' : (isBox ? boxCount : unitCount) === (isBox ? qtyPerBox : minSaleUnits) ? 'binExpanded' : 'fadeOut'}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/images/svg/btn-bin.svg' alt='Icon bin' width='16' height='19'/>
        </motion.figure>
        <motion.figure key={`two-${(isBox ? boxCount : unitCount) > (isBox ? 1 : minSaleUnits)}`} initial={{ opacity: 0, scale: 0, rotate: 0 }} animate={(isBox ? boxCount : unitCount) > (isBox ? 1 : minSaleUnits) ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 0, scale: 0, rotate: 90 }} transition={{ duration: .3 }} exit={{ opacity: 0, rotate: 90 }} className='figure-minus'>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/images/svg/btn-minus.svg' alt='Icon minus sign' width='20' height='3' className='img-minus'/>
        </motion.figure>
      </Button>
    </motion.div>
  )
})

type AddButtonProps = {
  onClick: () => void
  className?: string
  frozen?: boolean
}

const AddButton:FC<AddButtonProps> = memo(({ onClick, className = '', frozen }) => {
  return (
    <motion.div className={className} initial='collapsed' variants={defaultVariants} exit='collapsed' animate={'expanded'}>
      <Button circle={true} bgType={frozen ? 'frozen' :'red'} onClick={onClick}>
        <figure>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/images/svg/btn-sign-icon.svg' alt='Icon add to cart' width='16' height='16'/>
        </figure>
      </Button>
    </motion.div>
  )
})

type TypeAddToCart = {
  data?: TypeCartLine | {
    [key:string]: any,
    variant: {
      id: number,
      bigcommerceId: string | number,
      minSaleUnits?: number
      qtyPerBox?: number
      stock: number
    }
  },
  isPDP?: boolean
  setHasUnitsInCart?: any,
  isCart?: boolean,
  displayStockWarning?: boolean
  notAvailable?: boolean
  inventoryTracking: boolean
  frozen?: boolean
  shouldTriggerHowToBuyModal?: boolean
  isBox?: boolean
}


function checkIfEnoughStock({ stock, minSaleUnits, qtyPerBox, unitCount, isBox }) {
  const soldByBox = minSaleUnits === 1 && qtyPerBox > 1
  // const soldByMinSaleUnits = minSaleUnits > 1 && qtyPerBox >= 1
  // const soldByUnit = minSaleUnits === 1 && qtyPerBox === 1

  if (isBox && soldByBox) return stock >= unitCount + qtyPerBox
  return stock >= (unitCount + minSaleUnits)
}


function getUpdatedStock({ variant, cartLine }) {
  if(typeof cartLine?.variant?.stock === 'number') return cartLine.variant.stock
  return typeof variant?.stock === 'number' ? variant.stock : 0
}

export const AddToCart = memo(({ data, setHasUnitsInCart = null, isPDP = false, isCart = false, displayStockWarning = false, notAvailable = false, inventoryTracking = null, frozen = false, shouldTriggerHowToBuyModal = true, isBox = false }:TypeAddToCart) => {
  const wrapperRef = useRef(null)
  const { variant } = data

  // Our react cart Context
  const { cartLines, addToCart, removeFromCart, bulkUpdateCart, isFrozenCart } = useCart()
  const existingLineItem = useMemo(() => cartLines.find(item => item?.variant?.id === variant.id), [cartLines, variant])
  const stock = useMemo(() => getUpdatedStock({ variant, cartLine: existingLineItem }), [variant, existingLineItem])

  // const stock2 = useMemo(() => !existingLineItem ? stock : existingLineItem.stock, [stock])
  const { locale, pathname, push } = useRouter()
  const { t } = useTranslator()
  const { isMobile, size } = useDeviceType()
  // const { toggle:openModalPublicInterested } = useModal(MODALS.PUBLIC_INTERESTED)
  const { toggle:openModalAuth } = useModal(MODALS.AUTH)
  const { toggle:openModalComercial } = useModal(MODALS.COMERCIAL)
  const { toggle:openModalCartType } = useModal(MODALS.CART_TYPE)
  const { cardSelected, removeActiveVariant} = useActiveVariant()
  const { customer } = useCustomer()
  const { addToast } = useToast()

  const minSaleUnits = useMemo(() => typeof variant?.minSaleUnits !== 'number' ? 1 : variant.minSaleUnits, [variant])
  const qtyPerBox = useMemo(() => typeof variant?.qtyPerBox !== 'number' ? 1 : variant.qtyPerBox, [variant])

  const [inputUnitsValue, setInputUnitsValue] = useState(0)

  const [unitCount, setUnitCount] = useState(0)
  const boxCount = useMemo(() => getBoxCount({ isBox, unitCount, qtyPerBox }), [isBox, unitCount, qtyPerBox])
  const productCount = useMemo(() => isBox ? boxCount : unitCount, [isBox, boxCount, unitCount])
  const enoughStock = useMemo(() => checkIfEnoughStock({ qtyPerBox, minSaleUnits, stock, unitCount, isBox }), [qtyPerBox, minSaleUnits, stock, isBox, unitCount])

  // Grouping clicks to trigger less CMS API request
  const [clickTimestamp, setClickTimestamp] = useState(0)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const isPrivate = useMemo(() => getPathnameType(pathname) === 'private',[pathname])

  const animate = useMemo(() => isPDP ? 'expandedPDP' : (size.width >= 603 && size.width <768) ? 'expandedNexus': isMobile ? 'expandedMobile' : (size.width >= 1650) ? 'expandedWide': 'expandedDesktop', [isMobile, isPDP, size.width])

  const [backToSingleUnit, setBackToSingleUnit] = useState(false)
  const { setCartItems } = useContext(NewCartItemsContext) || {}
  // graphQL mutations
  const [cartLinesAdd, { data:dataAfterAdd }] = useMutation(CUSTOMER_ADD_TO_CART, {
    fetchPolicy: 'no-cache'
  })

  const [cartLinesUpdate, { data:dataAfterUpdate }] = useMutation(CUSTOMER_UPDATE_CART_ITEM, {
    fetchPolicy: 'no-cache',
    update: (cache) => {
      // Read the data from the cache for the relevant query
      // const cachedData: { customer: TypeCart['customer'] } | null = cache.readQuery({ query: GET_CART })
      let cachedData = null
      if(cache) cachedData = cache.readQuery({ query: GET_CART })
      // console.log('⭕️ cache', cache)
      console.log('⭕️⭕️ cD', cachedData)
      // console.log('⭕️ cartLinesUpdate', cartLinesUpdate)

      // // Check if cachedData and customer are not null before accessing properties
      // if (cachedData && cachedData.customer && cachedData.customer.cart) {
      //   // Update cart properties with the mutation response
      //   cachedData.customer.cart = { ...cachedData.customer.cart, ...cartLinesUpdate.cart }
      //   // Write the updated data back to the cache
      //   cache.writeQuery({
      //     query: GET_CART,
      //     data: { customer: { ...cachedData.customer } },
      //   })
      // } else if (!cachedData) {
      //   cache.writeQuery({
      //     query: GET_CART,
      //     data: { customer: { cart: { ...cartLinesUpdate.cart, boughtTogether: [] }}}
      //   })
      // }
    }
  })

  const [cartLinesDelete, { data:dataAfterDelete }] = useMutation(CUSTOMER_DELETE_FROM_CART)

  const onInputUnitsChange = useCallback(e => {
    setInputUnitsValue(e.target.value)
  }, [setInputUnitsValue])

  // ⚠️ NEW INPUT UNITS
  const getValidValue = useCallback((unitCountValue, stock, notification = false) => {
    const minUnits = isBox ? qtyPerBox : minSaleUnits

    let numb = Number(unitCountValue) ? isBox ? Number(unitCountValue) * qtyPerBox : Number(unitCountValue) : minUnits
    if (numb > stock) {
      numb = stock
      if (notification) {
        addToast({
          message: getDynamicText(t('product_page.adjusted_stock' as TypeTranslationsJsonKeys) as string, {
            units: numb
          }),
          type: 'info'
        })
      }
    }
    if (numb % minUnits === 0) return numb
    else {
      // Find the nearest lower multiple of minUnits
      const lowerMultiple = Math.floor(numb / minUnits) * minUnits
      // Find the nearest higher multiple of minUnits
      const higherMultiple = lowerMultiple + minUnits

      let validMultiple = null
      // Determine which multiple is closer to units
      if ((numb - lowerMultiple) < (higherMultiple - numb)) {
        validMultiple = lowerMultiple
      } else {
        validMultiple = higherMultiple
      }

      if(validMultiple === 0 && higherMultiple <= stock) validMultiple = higherMultiple

      if(notification) {
        addToast({
          message: getDynamicText(t('product_page.adjusted_units' as TypeTranslationsJsonKeys) as string, {
            units: validMultiple
          }),
          type: 'info'
        })
      }
      return validMultiple
    }
  }, [addToast, t, isBox, qtyPerBox, minSaleUnits])

  const updateUnit = useCallback(async (increase = true, customValue = false) => {
    // First check if it's a valid cart type
    if (cartLines.length > 0 && ((isFrozenCart && !frozen) || (!isFrozenCart && frozen))) {
      openModalCartType()
      return
    }

    const initialInputValue = inputUnitsValue
    const variantId = variant.id
    let incomingValue = customValue ? Number(inputUnitsValue) + (isBox ? unitCount : 0) : 0
    if(!customValue) {
      if(!increase) {
        if(isBox) {
          incomingValue = inputUnitsValue - qtyPerBox
        } else {
          // if trying to delete the last allowed unit/box
          if (inputUnitsValue <= 1 ||(minSaleUnits > 1 && inputUnitsValue <= minSaleUnits)) incomingValue = 0
          else {
            incomingValue = inputUnitsValue - minSaleUnits
          }
        }
      } else {
        if(isBox) {
          const remainder = inputUnitsValue % qtyPerBox
          incomingValue = ((boxCount + 1) * qtyPerBox) + remainder
        } else {
          if(inputUnitsValue === 0) incomingValue = minSaleUnits
          else {
            incomingValue = inputUnitsValue + minSaleUnits
          }
        }
      }
    }

    if (inventoryTracking && incomingValue > stock) {
      const remainder = stock % (isBox ? qtyPerBox : minSaleUnits)
      incomingValue = stock - remainder
    }

    setBackToSingleUnit(incomingValue === (isBox ? qtyPerBox : minSaleUnits))
    setHasUnitsInCart && setHasUnitsInCart(incomingValue > 0)

    setUnitCount(incomingValue)
    setInputUnitsValue(incomingValue)
    let incomingLineItem = null

    if (typeof existingLineItem === 'object') {
      incomingLineItem = { ...existingLineItem }
      incomingLineItem.quantity = incomingValue
    }

    // clicker
    const now = Date.now()
    if (timeoutId && (now - clickTimestamp < 900)) clearTimeout(timeoutId)

    const newTimeoutId = setTimeout(async () => {
      const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
      // Update CMS
      const mutationData = {
        variables: {
          variantId,
          quantity: incomingValue, //TODO ASK ALEX WHAT TO DO WITH BOX FORMAT
          cartLineType: CART_LINE_TYPE.PHYSICAL
        },
        context: {
          isPrivatePath: true,
          DYCustomerToken
        }
      }

      if (increase && (!existingLineItem || existingLineItem?.quantity === 0) && (incomingValue/minSaleUnits === 1)) {
        await cartLinesAdd(mutationData).catch((error) => {
          console.warn('Error with mutation: cartLinesAdd:', error)
          setUnitCount(initialInputValue)
          setInputUnitsValue(initialInputValue)
        })

        pushAnalyticsEvent('add_to_cart', { ...data, variant, units: incomingValue })
      } else if (!increase && incomingValue === 0) {
        await cartLinesDelete(mutationData).catch((error) => {
          console.warn('Error with mutation: cartLinesDelete:', error)
          setUnitCount(initialInputValue)
          setInputUnitsValue(initialInputValue)
        })
        pushAnalyticsEvent('remove_from_cart', { ...data, variant, units: incomingValue })
      } else {
        await cartLinesUpdate(mutationData).catch((error) => {
          console.warn('Error with mutation: cartLinesUpdate:', error)
          setUnitCount(initialInputValue)
          setInputUnitsValue(initialInputValue)
        })
        pushAnalyticsEvent('add_to_cart', { ...data, variant, units: incomingValue })
      }
      // End update CMS
    }, 900)

    setTimeoutId(newTimeoutId)
    setClickTimestamp(now)
    // End clicker

    // Update context
    if (incomingLineItem) {

      if (increase) addToCart(incomingLineItem)
      else removeFromCart(incomingLineItem)
    }
  }, [data, minSaleUnits, cartLines, addToCart, cartLinesAdd, cartLinesUpdate, cartLinesDelete, unitCount, removeFromCart, setHasUnitsInCart, variant, clickTimestamp, timeoutId, inputUnitsValue, stock, isBox, existingLineItem, frozen, isFrozenCart, openModalCartType, qtyPerBox, inventoryTracking, boxCount])

  const onInputUnitsKeyUp = useCallback(e => {
    if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
      console.log('Enter pressed ⤴️ !!!', inputUnitsValue)
      const val = getValidValue(inputUnitsValue, stock)
      e.target.value = val
      updateUnit(true, true)
    }
  }, [updateUnit, inputUnitsValue, getValidValue, stock])

  const onBlur = useCallback((e) => {
    // Check if the new focused element is outside the wrapperRef
    if (!wrapperRef.current.contains(e.relatedTarget)) {
      setInputUnitsValue(productCount)
      e.target.value = productCount
    }
  }, [productCount])

  const deleteCartLine = async () => {
    const variantId = variant.id
    setUnitCount(0)
    setInputUnitsValue(0)

    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    
    try {
      cartLinesDelete({
        variables: {
          variantId
        },
        context: {
          isPrivatePath: true,
          DYCustomerToken
        }
      })
      // deleteFromCart(variantId)
    } catch (e) {
      console.warn('cartLinesDelete catch: ', e)
    }
  }

  // Check if variant product exist in the cart and update it's quantity
  useEffect(() => {
    const item = Array.isArray(cartLines) ? cartLines.find(item => item?.variant?.id === variant.id) : false
    if (item) {
      setUnitCount(item.quantity)
      setInputUnitsValue(item.quantity)
      setBackToSingleUnit(variant.minSaleUnits === item.quantity)
      setHasUnitsInCart && setHasUnitsInCart(item.quantity > 0)
      // PENDING setHasUnitsInCartForBoxes
    } else {
      setUnitCount(0)
      setInputUnitsValue(0)
      setBackToSingleUnit(false)
      setHasUnitsInCart && setHasUnitsInCart(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLines, variant.id, variant.minSaleUnits])

  useEffect(() => {
    if (!dataAfterAdd) return
    let cartRes = dataAfterAdd?.cartLinesAdd?.cart
    if(Array.isArray(cartRes?.cartLines)) {
      const newItemCart = cartRes.cartLines.find(item => item?.variant?.id ===  variant?.id)
      if(typeof newItemCart === 'object') {
        setCartItems && setCartItems(prevValue => {
          const prevArray =  [...prevValue]
          const matchedCartItem = prevArray.indexOf(item => item.variant?.id === newItemCart.variant?.id)
          if(matchedCartItem === -1) return [...prevValue, newItemCart]
          else {
            prevArray[matchedCartItem] = newItemCart
            return prevArray
          }
        })
      }
    }
    console.log('📀📀 CMS cartRes dataAfterAdd', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = cartRes
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterAdd])

  useEffect(() => {
    if (!dataAfterUpdate) return
    let cartRes = dataAfterUpdate?.cartLinesUpdate?.cart

    console.log('📀📀 CMS cartRes dataAfterUpdate', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId: cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = cartRes
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterUpdate])

  useEffect(() => {
    if (!dataAfterDelete) return
    let cartRes = dataAfterDelete?.cartLinesDelete?.cart

    console.log('📀📀 CMS cartRes dataAfterDelete', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId: cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = cartRes

      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterDelete])

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [timeoutId])

  const redirectToPage = () => {
    const isPanettone = data.product.name.toLowerCase().includes('panettone')
    if(isPanettone) return
    if(cardSelected) removeActiveVariant()
    if(locale === 'de-DE') {
      push('/page/kontaktieren-sie-uns')
    } else if(locale === 'pt-PT') {
      push('/page/contacte-nos')
    }
  }

  return (
    <>
      <ButtonWrapper ref={wrapperRef} className={`${isPDP ? 'is-pdp' : isCart ? 'is-cart' : ''}`}>
        {isPDP ?
          <Button className={`gtm_add-to-cart btn-add--pdp${productCount > 0 ? ' hide-initial-add' : ''}${(isPrivate && inventoryTracking && !enoughStock) ? ' no-stock' : notAvailable ? ' not-available' : ''}`} bgType={frozen ? 'frozen' :'red'} onClick={isPrivate ? updateUnit : locale === 'de-DE' || locale === 'pt-PT' ? redirectToPage : shouldTriggerHowToBuyModal ? openModalComercial : null}>
            {isPrivate ? t(!inventoryTracking || stock >= minSaleUnits ? 'commons.actions.add_to_cart' : 'product_page.product.no_stock') as string : t('commons.actions.how_to_buy') as string}
          </Button>
          :
          <>
            {(isPrivate && inventoryTracking && productCount === 0 && (!enoughStock || notAvailable)) ?
              <Button className={`btn-add insufficient-stock ${cardSelected ? 'selected-card' : ''}`} circle={cardSelected ? true : false} bgType={'red'} disabled name={t('product_page.product.no_stock') as string}>
                {cardSelected ?
                  <figure>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src='/images/svg/btn-sign-icon.svg' alt='Icon add to cart' width='16' height='16'/>
                  </figure>
                  :
                  <>{notAvailable ? t('product_page.product_main.not_available') : t('product_page.product.no_stock')}</>
                }
              </Button>
              :
              <>
                {/* isPrivate was added by marketing decision to hide plus button until fully release the public site with the signup enabled */}
                {isPrivate && <Button className={`gtm_add-to-cart btn-add ${!enoughStock && inventoryTracking ? ' insufficient-stock' : ''}`} circle={true} bgType={frozen ? 'frozen' :'red'} onClick={isPrivate ? updateUnit : hasPrivateStore[locale] ? openModalAuth : openModalComercial} name={t('commons.actions.add_to_cart') as string}>
                  <figure>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src='/images/svg/btn-sign-icon.svg' alt='Icon add to cart' width='16' height='16'/>
                  </figure>
                </Button>}
              </>
            }
          </>
        }
        <AnimatePresence mode='wait'>
          {productCount > 0 &&
          <>
            <Counter className={`counter ${isPDP ? ' is-pdp' : ''}`} isPDP={isPDP} initial={isPDP ? 'collapsedPDP' : (size.width >= 603 && size.width <= 768) ? 'collapsedNexus' : isMobile ? 'collapsedMobile' : (size.width >= 1650) ? 'collapsedWide': 'collapsedDesktop'} variants={counterVariants} exit={isPDP ? 'collapsedPDP' : (size.width >= 603 && size.width <= 768) ? 'collapsedNexus' : isMobile ? 'collapsedMobile' : (size.width >= 1650) ? 'collapsedWide':'collapsedDesktop'} animate={animate} $frozen={frozen}>
              <Units key={unitCount} className='units' initial={{ y: -15, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: .3 }} defaultValue={productCount} type='text' pattern='^([1-9][\d]{0,5}|0)$' onChange={onInputUnitsChange} onKeyUp={onInputUnitsKeyUp} onBlur={onBlur} />
            </Counter>
            <ButtonDelete unitCount={unitCount} isPDP={isPDP} backToSingleUnit={backToSingleUnit} minSaleUnits={minSaleUnits} onClick={updateUnit} onClickBin={deleteCartLine} frozen={frozen} isBox={isBox} cartLinesQuantity={existingLineItem?.quantity ?? 0} qtyPerBox={qtyPerBox}/>
            {isPDP && <AddButton className={`gtm_add-to-cart mini btn-add${(!enoughStock && inventoryTracking) ? ' insufficient-stock' : ''}`} onClick={updateUnit} frozen={frozen}/>}
          </>
          }
        </AnimatePresence>
        {(displayStockWarning && customer) && <span className='last_units'>{t('product_page.product.last_units')}</span>}
      </ButtonWrapper>

    </>
  )
})
