import { useEffect, useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client'
import type { NormalizedCacheObject } from '@apollo/client'
import { cookie } from '@dy/commons/utils'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined
const httpLinkCMS = new HttpLink({ uri: `https://${process.env.DY_CMS}/graphql` })
const httpLinkBigCommerce = new HttpLink({ uri: `https://${process.env.NEXT_PUBLIC_BIGCOMMERCE_STOREFRONT_URL}/graphql`, credentials: 'include' })

function createApolloClient(initialState) {
  const authCMS = new ApolloLink((operation, forward) => {
    const operationContext = operation.getContext()
    const isPrivatePath = operationContext.isPrivatePath
    // const customerId = operationContext.customerId
    const DYCustomerToken = operationContext.DYCustomerToken
    const DYResetPasswordToken = operationContext.DYResetPasswordToken
    const DYInviteToken = operationContext.DYInviteToken
    const token = isPrivatePath ? process.env.DY_PRIVATE_STORE_ACCESS_TOKEN : process.env.DY_PUBLIC_CATALOG_ACCESS_TOKEN
    // console.log(`🔑 DY CMS graphQL token (private:${isPrivatePath}):`, token)

    operation.setContext({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'DY-Access-Token': `${token}`,
        // ...(customerId && { 'BC-Customer-Id': customerId }),
        ...(DYCustomerToken && { 'DY-Customer-Token': DYCustomerToken }),
        ...(DYResetPasswordToken && { 'DY-Reset-Password-Token': DYResetPasswordToken }),
        ...(DYInviteToken && { 'DY-Invite-Token': DYInviteToken })
      },
      fetch
    })

    return forward(operation)
  })

  const authBigCommerce = new ApolloLink((operation, forward) => {
    const operationContext = operation.getContext()
    const isPrivatePath = operationContext.isPrivatePath
    const customerId = operationContext.customerId
    const token = isPrivatePath ? process.env.BIGCOMMERCE_PRIVATE_CHANNEL_GRAPHQL_AUTH_TOKEN : process.env.BIGCOMMERCE_PUBLIC_CHANNEL_GRAPHQL_AUTH_TOKEN
    // console.log(`🔑 BigCommerce DEVELOPMENT graphQL token (private:${isPrivatePath}):`, token)

    operation.setContext({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        ...(customerId && { 'x-bc-customer-id': customerId })
      },
      fetch
    })

    return forward(operation).map(response => {
      const context = operation.getContext()
      const cookies = context.response.headers.get('set-cookie')
      // console.log('🟣🔴 BC context.response.headers', context.response.headers)
      // console.log('🟣🟠 BC response headers - bccookies', cookies)
      if(response.data && cookies) {
        const SHOP_TOKEN = cookie.extract(cookies, 'SHOP_TOKEN')
        const SHOP_SESSION_TOKEN = cookie.extract(cookies, 'SHOP_SESSION_TOKEN')
        response.data.cookiesToSet = { SHOP_TOKEN, SHOP_SESSION_TOKEN }
      }

      return response
    })
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.split(
      operation => operation.getContext().endpoint === 'BC',
      concat(authBigCommerce, httpLinkBigCommerce), // <= apollo will send to this if context.endpoint is 'BC'
      concat(authCMS, httpLinkCMS) // <= otherwise will send to our CMS
    ),
    cache: new InMemoryCache().restore(initialState),
    credentials: 'include'
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient(initialState)

  // If the page has data fetching methods that use Apollo Client, the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export function useApollo(initialState:any) {
  const clientStore = useMemo(() => initializeApollo(initialState), [initialState])

  // Clear the client's store on unmount to prevent memory leaks
  useEffect(() => {
    return () => { clientStore.clearStore() }
  }, [clientStore])
  return clientStore
}
