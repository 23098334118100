import { useMemo, memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import { Button } from '@dy/commons/components'
import { HOSTNAME, currencyFormatter, getLang } from '@dy/commons/utils'
import { useScrollTracker, useCart } from '@dy/commons/hooks'
import { useTranslator } from '@/hooks'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, hex2Rgba, hideScrollbar } from '@dy/commons/styles'
import { ModalSummary, _Price } from '.'
import { CartItem } from './CartItem'
import { itemVariants, listVariants } from './cartVariants'
import type { TypeCartLine } from '@dy/commons/schema'
// import { useMutation } from '@apollo/client'
// import { MUTATION_CART_SYNC } from 'api/graphql'

interface ICartItemsAll {
  showCart: boolean,
  setShowCart: any,
  hasBanners: boolean
}

const getProducts = (products, push, urlHref) => products.map((product, idx) => (
  <CartItem key={`cartitem-${idx}`} product={product} id={`cartitem-${idx}`} onClick={() => push(urlHref)} />
))

const cartUrl = {
  cart_url: '/cart',
  checkout_url: 'https://localhost:3000/checkout'
}

export const CartItemsAll = memo<ICartItemsAll>(({ showCart, setShowCart, hasBanners }) => {
  const scrollState = useScrollTracker(1)
  const { locale:localeRouter, push, pathname } = useRouter()
  const { t } = useTranslator()
  const locale = getLang(localeRouter)
  const [products, setProducts] = useState<TypeCartLine[]>([])
  const { cartLines, subtotalExTax } = useCart()

  useEffect(() => {
    if (cartLines) setProducts(cartLines)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLines])

  const Products = useMemo(() => products && getProducts(products, push, cartUrl.cart_url), [push, products])
  if(!Products) return null

  return (
    <AnimatePresence mode={'wait'}>
      {showCart && (
        <>
          <ModalAllOverlay $active={showCart} onMouseEnter={() => setShowCart(false)} />
          <ModalAllListWrapper $scrollTop={scrollState === 'TOP'} $hasBanners={hasBanners} variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'}>
            <ModalAllList key='itemsincart' variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'}>
              {Products}
              <ModalSummary key={'summary'} variants={itemVariants}>
                <span>{t('commons.cart.total')} ({Products.length})</span>
                <span>{currencyFormatter({ price: subtotalExTax, localeRegion: locale, round: false, trailingZeros: true })}</span>
                {!pathname.includes('cart') && <Button outline onClick={() => { window.location.href=`https://${HOSTNAME}/cart?d=${Date.now()}` }} bgType={'red'}>{t('commons.cart.show_cart')}</Button>}
                {/* <Button disabled={isDisabled} onClick={onClickCheckout} bgType={'red'}>{t('commons.cart.go_checkout')}</Button> */}
              </ModalSummary>
            </ModalAllList>
          </ModalAllListWrapper>
        </>
      )}
    </AnimatePresence>
  )
})


const ModalAllOverlay = styled.div<any>`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  user-select: none;
  pointer-events: none;
  transition: opacity 300ms ${({ theme }) => theme.ease};
  z-index: 400;

  ${({ $active }) => $active && css`pointer-events: initial; user-select: initial;`};
`


const ModalAllListWrapper = styled(motion.div)<{ $scrollTop: boolean, $hasBanners: boolean }>`
  position: fixed;
  right: ${vw(5, 'mobile')};
  top: 70px;
  transition: 300ms top ease-out;
  z-index: 5000;

  ${mq.greaterThan('nexus7')} {
    right: ${vw(5, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    right: ${vw(15, 'desktop')};
    top: ${({$scrollTop,  $hasBanners}) => vw($scrollTop ? $hasBanners ? 110 : 95 : 70, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    right: 15px;
    top: ${({$scrollTop,  $hasBanners}) => $scrollTop ? $hasBanners ? '110px': '95px': '70px'};
  }

  ${mq.greaterThan('desktop-wide')} {
    right: calc((100% - 1610px) / 2);
  }


  &:before {
    border-bottom: ${vw(10, 'mobile')} solid ${({ theme }) => theme.colors.greylight};
    border-left: ${vw(9, 'mobile')} solid transparent;
    border-right: ${vw(9, 'mobile')} solid transparent;
    top: -${vw(8, 'mobile')};
    content: '';
    height: 0;
    position: absolute;
    right: ${vw(10, 'mobile')};
    width: 0;

    ${mq.greaterThan('nexus7')} {
      border-bottom: ${vw(10, 'nexus7')} solid ${({ theme }) => theme.colors.greylight};
      border-left: ${vw(9, 'nexus7')} solid transparent;
      border-right: ${vw(9, 'nexus7')} solid transparent;
      top: -${vw(8, 'nexus7')};
      right: ${vw(10, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-bottom: ${vw(10, 'desktop')} solid ${({ theme }) => theme.colors.greylight};
      border-left: ${vw(9, 'desktop')} solid transparent;
      border-right: ${vw(9, 'desktop')} solid transparent;
      top: -${vw(8, 'desktop')};
      right: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-bottom: 10px solid ${({ theme }) => theme.colors.greylight};
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      top: -8px;
      right: 20px;
    }
  }
`

const ModalAllList = styled<any>(motion.dl)`
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: flex-start;
  width: ${vw(308, 'mobile')};
  position: relative;
  overflow: scroll;

  ${hideScrollbar()}

  ${mq.greaterThan('nexus7')} {
    width: ${vw(308, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    display: flex;
    max-height: ${vw(370, 'desktop')};
    width: ${vw(308, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    max-height: 370px;
    width: 308px;
  }
`
