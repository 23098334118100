import { memo, useMemo, useEffect, useCallback, useContext} from 'react'
import { useRouter } from 'next/router'
import { Figure, Link } from '@dy/commons/components'
import { SectionAccount as Section, CardListItem, ProductListItem } from './sharedAccountStyles'
import { currencyFormatter, getLang, parseDateString, cookie, CART_LINE_TYPE } from '@dy/commons/utils'
import type { iCustomerOrder } from '@dy/commons/schema'
import { useTranslator } from '@/hooks'
import { RepeatOrder } from 'components/cart'
import { CUSTOMER_ADD_TO_CART } from '@/api'
import { useCart } from '@dy/commons/hooks'
import { useMutation } from '@apollo/client'
import { NewCartItemsContext } from '@/components'
import { mq, vw, getP30 } from '@dy/commons/styles'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { listVariants } from '../shop/shopVariants'
interface ISectionOrders {
  // TODO Update with latest schema
  data: iCustomerOrder
}

const mediaNarrowIcon = {
  type: 'image' as const,
  alt: 'Icon pasta 1',
  default: {
    src: '/images/svg/i--account-narrow.svg',
    size: { width: 8, height: 13 }
  }
}
const variantAvailable = importStatus => (importStatus !== 'archived' &&  importStatus !== 'to_delete')
const getProducts = (orderLines, locale, repeatOrderLine, t) => orderLines.map((item, idx) => {
  const { priceExTax, quantity, productName, brandName, variant } = item
  const isVariantAvailable = typeof variant === 'object' ? variantAvailable(variant?.importStatus) : false
  const priceCurrency = currencyFormatter({ price: priceExTax, localeRegion: locale, decimals:2, trailingZeros: false, round: false})
  // const pricePerWeightCurrency = currencyFormatter({price: pricePerWeight, localeRegion: locale})
  const thumbnail = variant?.media?.[0]?.medium

  return (
    <ProductListItem key={`${idx}-${variant?.id}`}>
      {thumbnail ? <Figure media={thumbnail} /> : <figure className='placeholder' />}
      <div className={'info'}>
        <span>{quantity} x {productName}</span>
        <span>{brandName}{variant?.name ? ` | ${variant?.name}` : ''} </span>
        <span>{priceCurrency}</span>
      </div>
      <button disabled={!isVariantAvailable} onClick={() => {
        repeatOrderLine(item)
      }}>{isVariantAvailable ? t('account.buy_again') : t('account.order.no_stock')}</button>
    </ProductListItem>
  )
})

const getOrders = (items, t, locale, repeatOrderLine) => items.map((item, idx) => {
  const { bigcommerceId, dateCreated, totalExTax:total, deliveryDate, orderLines } = item
  const ProductList =  getProducts(orderLines, locale, repeatOrderLine, t)
  const priceCurrency = currencyFormatter({price: total, localeRegion: locale, decimals:2, trailingZeros: false, round: false})

  return (
    <CardListItem key={`${idx}-${bigcommerceId}`}>
      <div className='mobile'>
        <p className={'headline_mobile'}>{deliveryDate}</p>
        <button className='icon__mobile'>
          <div className={'overview mobile'} >
            <span>{t('account.order.purchase')}<span>online</span> </span>
            <span>{t('account.order.date')} <span>{parseDateString({ dateString: dateCreated })}</span> </span>
            <span>{t('account.order.total')} <span>{priceCurrency}</span> </span>
            <span>{t('account.order.number')}<span>{bigcommerceId}</span> </span>
          </div>
          <Link href={`/account/order?id=${bigcommerceId}`}>
            <Figure media={mediaNarrowIcon}/>
          </Link>
        </button>
        <div className={'products_mobile'}>
          <p className={'headline'}>{deliveryDate}</p>
          <ul>
            {ProductList}
          </ul>
          <RepeatOrder orderLines={orderLines} />
        </div>
      </div>
      <div className={'overview desktop'} >
        <span>{t('account.order.purchase')}<span>online</span> </span>
        <span>{t('account.order.date')} <span>{parseDateString({ dateString: dateCreated })}</span> </span>
        <span>{t('account.order.total')} <span>{priceCurrency}</span> </span>
        <span>{t('account.order.number')}<span>{bigcommerceId}</span> </span>
      </div>
      <div className={'main-info'}>
        <div className={'products_desktop'}>
          <p className={'headline'}>{deliveryDate}</p>
          <ul>
            {ProductList}
          </ul>
        </div>
        <div className={'cta'}>
          <RepeatOrder orderLines={orderLines} />
          <Link href={`/account/order?id=${bigcommerceId}`}>{t('account.details')}</Link>
        </div>
      </div>
    </CardListItem>
  )
})

export const SectionOrders = memo<ISectionOrders>(({ data }) => {
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const [cartLinesAdd, { data:dataAfterAdd }] = useMutation(CUSTOMER_ADD_TO_CART)
  const { bulkUpdateCart } = useCart()
  const { setCartItems } = useContext(NewCartItemsContext) || {}

  const repeatOrderLine = useCallback(async orderLine => {
    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
    // Update CMS
    const mutationData = {
      variables: {
        variantId: orderLine.variant.id,
        quantity: orderLine.quantity,
        cartLineType: CART_LINE_TYPE.PHYSICAL
      },
      context: {
        isPrivatePath: true,
        DYCustomerToken
      }
    }

    await cartLinesAdd(mutationData).then((res) => {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = res.data.cartLinesAdd.cart
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
      setCartItems(cartLines)
    }).catch((error) => {
      console.warn('Error with mutation: cartLinesAdd:', error)
    })

  }, [bulkUpdateCart, cartLinesAdd, setCartItems])

  const OrderItems = useMemo(() => getOrders(data, t, locale, repeatOrderLine), [data, t, locale, repeatOrderLine])

  useEffect(() => {
    if (!dataAfterAdd) return
    let cartRes = dataAfterAdd?.cartLinesAdd?.cart
    // if(Array.isArray(cartRes?.cartLines)) {
    //   const newItemCart = cartRes.cartLines.find(item => item?.variant?.id ===  variant?.id)
    //   if(typeof newItemCart === 'object') {
    //     setCartItems && setCartItems(prevValue => {
    //       const prevArray =  [...prevValue]
    //       const matchedCartItem = prevArray.indexOf(item => item.variant?.id === newItemCart.variant?.id)
    //       if(matchedCartItem === -1) return [...prevValue, newItemCart]
    //       else {
    //         prevArray[matchedCartItem] = newItemCart
    //         return prevArray
    //       }
    //     })
    //   }
    // }
    console.log('📀📀 CMS cartRes dataAfterAdd', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications } = cartRes
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterAdd])

  return (
    <Section>
      {OrderItems.length === 0 ?
        <NoMessageP initial={'inactive'} animate={'active'} exit={'inactive'} variants={listVariants}>{t('account.no_orders')}</NoMessageP>
        :
        <ul>{OrderItems}</ul>
      }
    </Section>
  )
})

export const NoMessageP = styled(motion.p)<any>`
  ${getP30()}
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;
  opacity: .4;
  padding: ${vw(40, 'mobile')} ${vw(20, 'mobile')};
  text-align: center;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(40, 'nexus7')} ${vw(20, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 4 / span 9;
    margin: 0 auto;
    padding: ${vw(40, 'desktop')} 0;
    max-width: 600px;
  }

  ${mq.greaterThan('desktop')} {
    padding: 40px 0;
  }
`
