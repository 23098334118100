"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartProvider = exports.CartContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const initialCartState = { id: null, cartLines: [], subtotalExTax: 0, totalExTax: 0, deliveryDate: null, deliveryDateOptions: null, multipleDeliveryDates: false, discountAmount: 0, deliveryType: 'fresh', isFrozenCart: false, deliverySchedules: [], notifications: [] };
exports.CartContext = (0, react_1.createContext)(initialCartState);
const cartReducer = (state = initialCartState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'ADD':
        case 'REMOVE':
        case 'DELETE':
        case 'BULKUPDATE':
            return Object.assign(Object.assign({}, state), { id: payload.cartId, cartLines: payload.cartLines, subtotalExTax: payload.subtotalExTax, totalExTax: payload.totalExTax, discountAmount: payload.discountAmount, deliveryDate: payload.deliveryDate, deliveryDateOptions: payload.deliveryDateOptions, multipleDeliveryDates: payload.multipleDeliveryDates, notifications: payload.notifications });
        default:
            throw new Error(`cartReducer: No case for type: ${type}`);
    }
};
const CartProvider = ({ children }) => {
    const [state, dispatch] = (0, react_1.useReducer)(cartReducer, initialCartState);
    const [isFrozenCart, setIsFrozenCart] = (0, react_1.useState)(false);
    const addToCart = (productVariant) => {
        const index = state.cartLines.findIndex(item => item.variant.id === productVariant.variant.id);
        let updatedCart = [...state.cartLines];
        if (index !== -1) {
            updatedCart[index] = productVariant;
        }
        else {
            updatedCart.push(productVariant);
        }
        dispatch({ type: 'ADD',
            payload: {
                cartLines: updatedCart
            }
        });
    };
    const removeFromCart = (productVariant) => {
        const index = state.cartLines.findIndex(item => item.variant.id === productVariant.variant.id);
        let updatedCart = [];
        if (index !== -1 && productVariant.quantity > 0) {
            updatedCart = [...state.cartLines];
            updatedCart[index] = productVariant;
        }
        else {
            updatedCart = state.cartLines.filter(item => item.variant.id !== productVariant.variant.id);
        }
        dispatch({ type: 'REMOVE',
            payload: {
                cartLines: updatedCart
            }
        });
    };
    const deleteFromCart = (variantId) => {
        const updatedCart = state.cartLines.filter(item => item.variant.id !== variantId);
        dispatch({ type: 'DELETE',
            payload: {
                cartLines: updatedCart
            }
        });
    };
    const bulkUpdateCart = (cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications) => {
        dispatch({ type: 'BULKUPDATE',
            payload: {
                cartLines,
                cartId,
                subtotalExTax,
                totalExTax,
                deliveryDate,
                deliveryDateOptions,
                multipleDeliveryDates,
                discountAmount,
                deliveryType,
                deliverySchedules,
                notifications
            }
        });
    };
    (0, react_1.useEffect)(() => {
        function checkFrozenCart() {
            const isFrozen = state.cartLines.some(item => { var _a; return ((_a = item.variant) === null || _a === void 0 ? void 0 : _a.deliveryType) === 'frozen'; });
            setIsFrozenCart(isFrozen);
        }
        checkFrozenCart();
        console.log('🛒 Cart type:', isFrozenCart ? 'frozen' : 'fresh');
    }, [isFrozenCart, state.cartLines]);
    return ((0, jsx_runtime_1.jsx)(exports.CartContext.Provider, Object.assign({ value: {
            id: state.id,
            cartLines: state.cartLines,
            subtotalExTax: state.subtotalExTax,
            totalExTax: state.totalExTax,
            deliveryDate: state.deliveryDate,
            deliveryDateOptions: state.deliveryDateOptions,
            multipleDeliveryDates: state.multipleDeliveryDates,
            discountAmount: state.discountAmount,
            deliveryType: state.deliveryType,
            deliverySchedules: state.deliverySchedules,
            notifications: state.notifications,
            isFrozenCart,
            addToCart,
            removeFromCart,
            deleteFromCart,
            bulkUpdateCart
        } }, { children: children })));
};
exports.CartProvider = CartProvider;
