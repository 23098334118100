import { memo, useEffect, useMemo, useCallback } from 'react'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import { css } from 'styled-components'
import { useModal, useDeviceType } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { mq, vw } from '@dy/commons/styles'
import { cookie } from '@dy/commons/utils'
import { Modal } from '.'
import { useTranslator, useCustomer } from '@/hooks'
import { useCart } from '../../../commons/dist/hooks/useCart'
import { getAccountListItems } from '..'

export const dialogVariants = (isActive: boolean) => ({
  initial: { x: '-100%' },
  animate: { x: isActive ? 0 : '-100%' },
  exit: { x: '-100%' },
  transition: { duration: .3 }
})

export const ModalMenuAccount: FC = memo(() => {
  const { query, pathname, push } = useRouter()
  const { setCustomer } = useCustomer()
  const { isActive, toggle } = useModal(MODALS.MENU_ACCOUNT)
  const { isMobile } = useDeviceType()
  const { customer } = useCustomer()
  const { bulkUpdateCart } = useCart()
  const { t } = useTranslator()

  const closeSession = useCallback(() => {
    cookie.removeCookie('SHOP_TOKEN')
    cookie.removeCookie('SHOP_SESSION_TOKEN')
    cookie.removeCookie('BC_CUSTOMER_ID')
    cookie.removeCookie('DY_CUSTOMER_TOKEN')
    setCustomer(null)
    bulkUpdateCart([], null, 0, 0, null, null, false, 0, 'fresh', null, [])
    push('/')
  }, [setCustomer, bulkUpdateCart, push])

  const ListItems = useMemo(() => getAccountListItems(t('layout.nav_account'), query, pathname, toggle, closeSession), [t, query, pathname, toggle, closeSession])

  useEffect(() => {
    if ((!isMobile && isActive) || (!customer && isActive)) toggle()
  }, [isMobile, isActive, toggle, customer])

  return (
    <Modal isActive={isActive} close={toggle} title={t('layout.nav.account.name') as string} xAlign='flex-end' variants={dialogVariants} dialogStyles={dialogStyles} hasHeader={true} zIndex={10000000}>
      {ListItems}
    </Modal>
  )
})



export const dialogStyles = css`
  background: ${({ theme }) => theme.colors.white};
  margin: 0;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100vw;

  ${mq.greaterThan('tablet')} {
    max-width: ${vw(400, 'desktop')};
    width: auto;
  }

  ${ mq.greaterThan('desktop') } {
    max-width: 400px;
  }
`
