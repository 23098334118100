import { memo, useMemo, useState, useEffect } from 'react'
import type { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Navbar } from './nav/Navbar'
import { Footer } from './footer'
import { LoadingScreen } from '@/components'
import type { TypeSectionProductDetail } from '@/components'
import { cookie, getLang, STAGING, PRO } from '@dy/commons/utils'
// getRegion
import { useCart, useModal, useMount } from '@dy/commons/hooks'
import { useCustomer } from '@/hooks'
import { MODALS } from '@dy/commons/context'
import { GET_CART } from '@/api'
import { useQuery } from '@apollo/client'
import { CartItemsAll, CartItemsNew  } from '../cart'
import { mq } from '@dy/commons/styles'
import type { TypeGetCartQuery } from '@dy/commons/schema'
import { SlimBanners } from './SlimBanners'
import  { NewCartItemsContext } from '../cart/AddToCart'
// DEV ONLY
import { Susy } from '@dy/commons/styles'

const DynamicModalAuth = dynamic(() => import('@/components').then(mod => mod.ModalAuth))
const DynamicModalMenu = dynamic(() => import('@/components').then(mod => mod.ModalMenu))
const DynamicModalSearch = dynamic(() => import('@/components').then(mod => mod.ModalSearch))
const DynamicModalRegion = dynamic(() => import('@/components').then(mod => mod.ModalRegion))
const DynamicModalRegionRegister = dynamic(() => import('@/components').then(mod => mod.ModalRegionRegister))
const DynamicModalComercialRegion = dynamic(() => import('@/components').then(mod => mod.ModalComercialRegion))
const DynamicModalNewsletter = dynamic(() => import('@/components').then(mod => mod.ModalNewsletter))
const DynamicModalPromo = dynamic(() => import('@/components').then(mod => mod.ModalPromo))
const DynamicModalPublicInterested = dynamic(() => import('@/components').then(mod => mod.ModalPublicInterested))
const DynamicModalMenuAccount = dynamic(() => import('@/components').then(mod => mod.ModalMenuAccount))
const DynamicSectionProductDetail = dynamic<TypeSectionProductDetail>(() => import('@/components').then((mod) => mod.SectionProductDetail), { ssr: false })
const DynamicCartType = dynamic(() => import('@/components').then((mod) => mod.ModalCartType), { ssr: false })
const DynamicModalBuyBox = dynamic(() => import('@/components').then((mod) => mod.ModalBuyBox), { ssr: false })

type Props = {
  data: any,
  children: ReactNode
}

let customerId = null

const getValidModal = (modals, type) => {
  let cleanModal = []

  modals.map((modal)=> {
    const { visibleFrom , visibleUntil, modalType } = modal

    const from = new Date(visibleFrom)
    const today = new Date()
    const to  = new Date(visibleUntil)
    if ((from <= today && to >= today) && modalType === type) {
      cleanModal.push(modal)
    }
  })

  return cleanModal
}

let queryChecked = false
export const Layout:FC<Props> = memo(({ children, data }) => {
  const { locale:localeRouter, query } = useRouter()
  const lang = getLang(localeRouter)
  // const region = getRegion(localeRouter)
  const isMounted = useMount()
  const { categories, modals, footers, services } = data
  const modalNewsletter = useMemo(() => getValidModal(modals, 'newsletter'),[modals])
  const modalRegular = useMemo(() => getValidModal(modals, 'regular'),[modals])

  const slimBanners = useMemo(() => data?.banners?.filter(el => el.bannerType === 'slim'),[data?.banners])
  const hasBanners = useMemo(() => slimBanners?.length > 0, [slimBanners])
  const [showCart, setShowCart] = useState(false)
  const [cartItems, setCartItems] = useState([])
  const { toggle:toggleModalNewsletter } = useModal(MODALS.NEWSLETTER)
  const { toggle:toggleModalPromo } = useModal(MODALS.PROMO)
  const { toggle:toggleModalRegion } = useModal(MODALS.REGION_LANG)
  const { toggle:toggleModalAuth, updatePayload:updateAuthPayload } = useModal(MODALS.AUTH)
  const { loaderActive, customer } = useCustomer()

  const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
  const { data:cartData, loading, error } = useQuery<TypeGetCartQuery>(GET_CART, {
    context: {
      isPrivatePath: true,
      DYCustomerToken
    },
    skip: !DYCustomerToken, // Skip the query if no customer token
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  })
  const { bulkUpdateCart } = useCart()

  useEffect(() => {
    const cookieModalPromo = cookie.get('DY_MODAL_PROMO')
    const cookieCatalogRegion = cookie.get('DY_CATALOG_REGION')
    const cookieModalNewsletter = cookie.get('DY_MODAL_NEWSLETTER')

    if (localeRouter !== 'en-EU') {
      // Cookie of other store
      // const currentStore = getStoreByLocale[localeRouter]
      // const cookieLocale = `${cookieCatalogRegion?.split('-')[0]?.toLowerCase()}-${cookieCatalogRegion?.split('-')[1]}`
      // const regionStore = getStoreByLocale[cookieLocale]
      // No cookie, first visit
      if (!cookieCatalogRegion) {
        if (localeRouter === 'es-ES') {
          setTimeout(() => {
            toggleModalRegion()
          }, 2500)
        } else {
          cookie.set({ name: 'DY_CATALOG_REGION', value: localeRouter.toUpperCase(), days: 365})
        }
      } else if (localeRouter !== 'es-ES') {
        cookie.set({ name: 'DY_CATALOG_REGION', value: localeRouter.toUpperCase(), days: 365 })
      } else if (localeRouter === 'es-ES' && (!cookieCatalogRegion.includes('ES-') || cookieCatalogRegion === 'ES-CT' || cookieCatalogRegion === 'ES-MD' || cookieCatalogRegion === 'ES-CN')) {
        // if we are in Spain and cookie is not any related Spain region
        console.info('🟡 Spain area, and cookie not Spain region related')
        // Display modal
        setTimeout(() => {
          toggleModalRegion()
        }, 2500)
      }

      // MODAL NEWSLETTER
      if(cookieCatalogRegion) {
        if (!DYCustomerToken && !cookieModalNewsletter && modalNewsletter.length > 0) {
          setTimeout(() => {
            toggleModalNewsletter()
          }, 3000)
        }

        if (!cookieModalPromo && modalRegular.length > 0) {
          setTimeout(() => {
            cookie.set({ name: 'DY_MODAL_PROMO', value: 'true', days: 1 })
            toggleModalPromo()
          }, 2000)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')

    if (DYCustomerToken && (typeof customer?.id === 'number' && (!customerId || customerId !== customer.id)) && !loading && !error && cartData?.cart) {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, discountAmount, deliveryDate, deliveryDateOptions, multipleDeliveryDates, deliveryType, deliverySchedules, notifications } = cartData.cart
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount, deliveryType, deliverySchedules, notifications)
      customerId = customer.id
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, loading, error, customerId, customer])

  useEffect(() => {
    if (typeof query === 'object' && Object.keys(query).length > 0 && !queryChecked) {
      const { reset, activate }  = query
      if(reset) {
        updateAuthPayload({ payload: { level: 'reset_password', resetToken: reset }, open: true })
      } else if (activate) {
        updateAuthPayload({ payload: { level: 'activation', activationToken: activate }, open: true })
      }
      queryChecked = true
    }
  }, [query, updateAuthPayload, toggleModalAuth])

  return (isMounted &&
    <LayoutStyled>
      <NewCartItemsContext.Provider value={{ cartItems, setCartItems }}>
        {localeRouter !== 'en-EU' && <>
          {hasBanners && <SlimBanners banners={slimBanners} />}
          <Navbar firstCategory={categories?.[0]?.slug?.[lang] ?? {}} setShowCart={setShowCart} hasBanners={hasBanners} />
          <CartItemsNew products={cartItems} hasBanners={hasBanners}/>
          <CartItemsAll showCart={showCart} setShowCart={setShowCart} hasBanners={hasBanners}/>
        </>}
        {children}
        <DynamicSectionProductDetail />
      </NewCartItemsContext.Provider>
      {modalRegular.length > 0 && <DynamicModalPromo data={modalRegular[0]} />}
      {modalNewsletter.length > 0 && <DynamicModalNewsletter data={modalNewsletter[0]} />}
      <DynamicModalMenu hasBanners={hasBanners} data={data}/>
      <DynamicModalPublicInterested />
      <DynamicModalRegion />
      <DynamicModalComercialRegion />
      <DynamicModalRegionRegister />
      <DynamicCartType />
      <DynamicModalBuyBox />
      {(localeRouter === 'es-CT' || localeRouter === 'es-MD') && <>
        <DynamicModalMenuAccount />
        <DynamicModalAuth />
      </>}
      {localeRouter !== 'en-EU' && <>
        <DynamicModalSearch hasBanners={hasBanners} categories={categories} />
        {footers && <Footer services={services} pages={footers} firstCategory={categories?.[1]}/>}
      </>}
      {(!STAGING && !PRO) && <Susy />}
      <LoadingScreen isActive={loaderActive} />
    </LayoutStyled>
  )
})


const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  min-height: 100vh;
  min-height: 100dvh;

  ${mq.greaterThan('tablet')} {
    min-width: 100vw;
    min-width: 100dvw;
    width: 100%;
    overflow-x: clip;
  }
`
