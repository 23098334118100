"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupByDate = exports.getDateFormatted = exports.getDayOfWeekName = exports.parseDateString = exports.isValidDate = void 0;
const isValidDate = (dateString) => !isNaN(Date.parse(dateString));
exports.isValidDate = isValidDate;
function parseDateString({ dateString, locale = 'es-ES', includeHour = false, includeSeconds = false, includeTimezone = false, }) {
    if (!dateString) {
        console.warn('🗓️ Invalid dateString format or missing value. Cannot be parsed.');
        return '';
    }
    let date;
    if (dateString instanceof Date) {
        // If already a Date object, use it directly
        date = new Date(Date.UTC(dateString.getUTCFullYear(), dateString.getUTCMonth(), dateString.getUTCDate(), dateString.getUTCHours(), dateString.getUTCMinutes(), dateString.getUTCSeconds()));
    }
    else if (typeof dateString === 'string') {
        // Extract the raw YYYY-MM-DD date components from the string
        const dateParts = dateString.split('T')[0].split('-').map(Number);
        if (dateParts.length !== 3) {
            console.warn(`🗓️ Error parsing dateString: ${dateString}. Ensure it's a valid ISO 8601 format.`);
            return '';
        }
        const [year, month, day] = dateParts;
        // Create a date in **pure UTC** to prevent any timezone shifting
        date = new Date(Date.UTC(year, month - 1, day));
    }
    else {
        console.warn(`🗓️ Invalid dateString type: ${typeof dateString}. Expected Date | string.`);
        return '';
    }
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    if (includeHour) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        if (includeSeconds)
            options.second = '2-digit';
    }
    if (includeTimezone)
        options.timeZoneName = 'short';
    return date.toLocaleString(locale, options);
}
exports.parseDateString = parseDateString;
function getDayOfWeekName(lang, dayOfWeek) {
    const options = { weekday: 'long', locale: lang };
    const formatter = new Intl.DateTimeFormat(lang, options);
    // Get the name of the week day based on the day of the week integer
    const dayName = formatter.format(new Date(Date.UTC(new Date().getFullYear(), 0, dayOfWeek)));
    return dayName;
}
exports.getDayOfWeekName = getDayOfWeekName;
function getDateFormatted(locale, dateString) {
    const date = new Date(dateString);
    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat(locale, options);
    const parts = formatter.formatToParts(date);
    const formattedDate = `${parts[0].value} ${parts[2].value}/${parts[4].value}`;
    return formattedDate;
}
exports.getDateFormatted = getDateFormatted;
function groupByDate(data = []) {
    if (!data.length)
        return [];
    const cartLines = [...data];
    // Sort the cartLines array by deliveryDate in ascending order
    cartLines.sort((a, b) => {
        const dateA = new Date(a.variant.deliveryDate);
        const dateB = new Date(b.variant.deliveryDate);
        return dateA.getTime() - dateB.getTime();
    });
    // Create a Map to store cartLines grouped by date
    const groupedByDate = new Map();
    // Iterate over the sorted array of cartLines
    cartLines.forEach(item => {
        const deliveryDate = new Date(item.variant.deliveryDate);
        // Create a key based on year, month, and day (ignoring hours and minutes)
        const key = new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate()).toString();
        // Add the object to the corresponding group in the Map
        if (groupedByDate.has(key)) {
            groupedByDate.get(key).push(item);
        }
        else {
            groupedByDate.set(key, [item]);
        }
    });
    // Convert the Map values to an array of arrays
    const result = Array.from(groupedByDate.values());
    return result;
}
exports.groupByDate = groupByDate;
