import { memo, useMemo } from 'react'
import type { FC } from 'react'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { useTranslator } from '@/hooks'
import { Button } from '@dy/commons/components'
import { css } from 'styled-components'

type MenuProps = {
}

type Notification = {
  code: string
  cartLine: {
    product: {
      name: string
    }
  }
  oldQuantity: number
  newQuantity: number
  deleted: boolean
}



const createParagraphsFromNotifications = (notifications: Notification[], t) => {
  if(!notifications) return null
  return notifications
    .filter(n => n.code === 'CART_LINE_QUANTITY_INVALID')
    .map(n => {
      const productName = n.cartLine.product.name
      const { newQuantity, deleted } = n

      if (deleted) {
        return `${productName} ${t('modals.stock_cart.deleted')} `
      }

      return `${productName} ${t('modals.stock_cart.stock_change_1')} ${newQuantity} ${t('modals.stock_cart.stock_change_2')}`
    })
}

export const ModalStockCart: FC<MenuProps> = memo(({ }) => {
  const { isActive, toggle, payload } = useModal(MODALS.STOCK_CART)
  const { t } = useTranslator()
  const Notifications = useMemo(() => createParagraphsFromNotifications(payload, t), [payload, t])


  return isActive && (
    <Modal isActive={isActive} close={toggle} title={t('modals.stock_cart.title') as string}  xAlign='flex-end' size='s' dialogStyles={dialogStyles} >
      <p>{t('modals.stock_cart.description')}</p>
      {Notifications}
      <Button onClick={toggle}>{t('modals.stock_cart.ok')}</Button>
    </Modal>
  )
})

const dialogStyles = css`
  .modal__body  {
    text-align: center;

    button {
      margin-top: 25px;
    }
  }
  span {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    
    &:last-child{
      margin-bottom: 25px;
    }
  }



`